import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { Link } from "@material-ui/core"

const FixedNav = () => {
  return (
    <AppBar style={{ backgroundColor: "rgb(0, 0, 0, 1)" }} position="static">
      <Toolbar>
        <Link
          href="/"
          color="inherit"
          variant="body1"
          style={{ textDecoration: "none" }}
        >
          <strong>chainworks</strong>
        </Link>
      </Toolbar>
    </AppBar>
  )
}

export default FixedNav
