import React from "react"
import PropTypes from "prop-types"
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core"
import { graphql } from "gatsby"
import "./portfolioProduct.css"
import Layout from "../components/layout"
import FixedNav from "../components/fixedNav"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      style={{ width: "100%" }}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

const PortfolioProduct = ({ data }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    }
  }

  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const postTabEdges = data.allFile.edges
  return (
    <Layout>
      <FixedNav />
      <Grid
        styles={{
          position: "relative",
          paddingBottom: "5px",
          paddingTop: "40px",
        }}
        container
      >
        <Grid
          style={{
            zIndex: 1,
            background: "#FFFFFF",
            marginTop: "40px",
            ...(isMdUp
              ? {
                  marginLeft: "25vh",
                  marginRight: "25vh",
                }
              : {
                  marginLeft: "5vh",
                  marginRight: "5vh",
                }),
          }}
          container
          item
          direction="column"
        >
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ paddingTop: "20px" }}
          >
            {!isXsDown ? (
              <Grid container item sm={3} justify="center">
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "Patua One",
                  }}
                >
                  {postTabEdges[0].node.childMarkdownRemark.frontmatter.title}
                </Typography>
              </Grid>
            ) : (
              <Grid container item xs={12} justify="center">
                <Typography
                  variant="h4"
                  style={{
                    fontFamily: "Patua One",
                  }}
                >
                  {postTabEdges[0].node.childMarkdownRemark.frontmatter.title}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ paddingTop: "20px" }}
          >
            {!isXsDown ? (
              <Grid item sm={3}>
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="Product tabs"
                >
                  {postTabEdges.map(
                    (
                      {
                        node: {
                          childMarkdownRemark: {
                            id,
                            frontmatter: { tab },
                          },
                        },
                      },
                      idx
                    ) => {
                      return <Tab label={tab} key={id} {...a11yProps(idx)} />
                    }
                  )}
                </Tabs>
              </Grid>
            ) : null}
            <Grid container item direction="row" xs={12} sm={9}>
              {!isSmUp ? (
                <Tabs
                  orientation="horizontal"
                  value={value}
                  onChange={handleChange}
                  aria-label="Product tabs"
                >
                  {postTabEdges.map(
                    (
                      {
                        node: {
                          childMarkdownRemark: {
                            id,
                            frontmatter: { tab },
                          },
                        },
                      },
                      idx
                    ) => {
                      return <Tab label={tab} key={id} {...a11yProps(idx)} />
                    }
                  )}
                </Tabs>
              ) : null}
              {postTabEdges.map(
                (
                  {
                    node: {
                      childMarkdownRemark: { id, html },
                    },
                  },
                  idx
                ) => {
                  return (
                    <TabPanel key={id} value={value} index={idx}>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </TabPanel>
                  )
                }
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}
PortfolioProduct.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
}

export const query = graphql`
  query($slug: String!) {
    allFile(
      filter: {
        fields: { slug: { eq: $slug } }
        internal: { mediaType: { eq: "text/markdown" } }
        name: { ne: "display" }
      }
      sort: { fields: childMarkdownRemark___frontmatter___tabIdx }
    ) {
      edges {
        node {
          childMarkdownRemark {
            id
            html
            frontmatter {
              tab
              title
            }
          }
        }
      }
    }
  }
`

export default PortfolioProduct
